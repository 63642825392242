export function set_cookie(name, value, days) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days); // Calculate expiration date

    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function get_cookies() {
    const cookies = document.cookie.split(";").map(function trim(s) {
        return s.trim();
    });
    const kv = cookies.map(cookie => cookie.split("="));
    return Object.fromEntries(kv);
}

export function get_cookie(name) {
    const cookies = get_cookies();
    return cookies[name];
}