console.log("_string");

export function leading_char_count(s, char) {
    const i = (s || "").split("").findIndex(ch => ch !== char);
    return i === -1 ? 0 : i;
}

export function split_trim_leading_space(multi_line_string) {
    const lines = multi_line_string.split("\n");
    if (lines.line === 1) {
        return lines;
    } else if (lines.length === 2) {
        return lines.map(line => line.trimStart());
    } else {
        const line0 = lines[0];
        const line1 = lines[1];

        const array_or_object = ["{", "["].includes(line0);
        const n_spaces0 = leading_char_count(line0, " ");
        const n_spaces1 = leading_char_count(line1, " ");
        const n_spaces = array_or_object ? Math.max(n_spaces1 - 4, 0) : line0.length > 0 ? n_spaces0 : n_spaces1;
        const spaces = Array(n_spaces).fill(" ").join("");
        return lines.map(line => line.startsWith(spaces) ? line.substr(n_spaces) : line);
    }
}

export function trim_leading_space(multi_line_string) {
    return split_trim_leading_space(multi_line_string).join("\n");
}