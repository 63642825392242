import {Render} from "nano/nano.jsx";
import {computed, ref} from "nano/reactive.jsx";
import IconChevronsRight from "solidjs-feather/IconChevronsRight.jsx";
import {sha256} from "nano/lib/security.js";
import {is_email_valid, is_password_valid} from "nano/lib/validation.js";
import api from "./api.jsx";
import "./dialog.css";

export default function Signup(props, children) {
    const {type, on_finish} = props;

    const username = ref("");
    const password = ref("");

    const button_label = ref("Continue");
    const disabled = computed(() => {
        return !username.value || !password.value;
    });

    async function onkeydown(event) {
        if (event.keyCode === 13) {
            await onok(event);
        }
    }

    function next_focus(id) {
        return function (event) {
            if (event.keyCode === 13) {
                const $el = document.getElementById(id);
                $el.focus();
            }
        };
    }

    async function onok(event) {
        if (disabled.value) {

        } else {
            const password_hash = await sha256(password.value);
            const message = {
                token: localStorage.getItem("token") || "",
                username: username.value,
                password_hash
            };
            const response = await api.post("/api/login", message);
            console.log(response);
            if (response.ok) {
                localStorage.setItem("token", response.token);
                localStorage.setItem("password_hash", password_hash);
                localStorage.setItem("username", username.value);

                window.postMessage({from: "translingo", token: response.token});
                onclose(event);
            } else {
                button_label.value = "Wrong username or password";
                password.value = "";
            }
        }
    }


    const dialog = Render(() => (
        <dialog id="login"
                class="dialog"
                onclose={onclose}
                style={{"max-width": "550px", "--background": `hsla(240, 20%, 90%, 0.98)`}}>
            <div class="dialog_content" onclick={onclick_content}>
                <section class="section section_title">
                    <h2 style={{margin: "auto"}}>{type}</h2>
                    {/*<p style="color: var(--apple_system_blue)">Cancel</p>*/}
                </section>
                {/*<section class="section section_input">*/}
                {/*    <label>*/}
                {/*        <span>Name: </span>*/}
                {/*    </label>*/}
                {/*    <input type="text" placeholder="How shall we call you" id="name"></input>*/}
                {/*</section>*/}
                <section class="section section_input">
                    <label>Username: </label>
                    <input type="text" placeholder="How shall we call you" model={username} class="input"
                           onkeydown={next_focus("password")}></input>
                </section>
                <section class="section section_input">
                    <label>Password: </label>
                    <input type="password" placeholder="At least 7 characters" model={password} class="input" id="password"
                           onkeydown={onkeydown}></input>
                </section>
                <section class="section section_button">
                    <div style={{width: "100%"}}>
                        <div>
                            <button style={{width: "100%"}} disabled={disabled} onclick={onok}>
                                <span>{button_label}</span>
                            </button>
                        </div>
                        {/*<div style={{display: "flex", "justify-content": "space-between", "margin": "10px 0"}}>*/}
                        {/*    <p>Not a member yet</p>*/}
                        {/*    <p>Signup</p>*/}
                        {/*</div>*/}
                    </div>
                </section>
            </div>
        </dialog>
    ));

    function onclick_content(event) {
        event.stopPropagation();
    }

    function onclick_dialog(event) {

        console.log(event);
        const {target} = event;
        if (target === dialog) {
            dialog.close();
        } else {
            //
        }
    }

    function onclose(event) {
        console.log(event);

        dialog.remove();
        on_finish();
    }

    // const portal = document.getElementById("portal");
    // portal.appendChild(dialog);
    document.body.appendChild(dialog);
    dialog.showModal();
}