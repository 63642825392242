import {add_event_listener} from "nano/dom.jsx";
import {deferred_install, fetch_vocabulary, installed_type, offline} from "./store.jsx";

import {set_cookie, get_cookie} from "./util.js";
// import PullToRefresh from "./PullToRefresh.js";
import "./app.css";
import {async_render, mount} from "nano/nano.jsx";
import play, {speak} from "./play.js";
import login from "./login.jsx";
import api from "./api.jsx";
import {start, route} from "./route.jsx";

// Example usage: Set a cookie named "myCookie" with value "example" that expires in 3 days


const onReady = async () => {

    console.log("DOMContentLoaded");

    const consoleError = console.error;
    window._user = localStorage.getItem("user");
    console.error = function remote_log(...args) {
        api.get("/api/log", {user: window._user, log: JSON.stringify(args)});
        consoleError(...args);
    };
    // function lockOrientation(orientation = "portrait") {
    //     if (screen.orientation && screen.orientation.lock) {
    //         screen.orientation.lock(orientation)
    //             .then(function () {
    //                 console.log(`Screen orientation locked to orientation mode`);
    //             })
    //             .catch(function (error) {
    //                 console.error("Error locking screen orientation:", error);
    //             });
    //     } else {
    //         console.error("Screen Orientation API is not supported on this device.");
    //     }
    // }
    //
    // lockOrientation();

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("sw.js").then(registration => {
            console.log(registration);
        }).catch(console.error);

        navigator.serviceWorker.addEventListener("message", function message_from_sw(event) {
            console.log(event);
        });
        window.addEventListener("appinstalled", function onappinstalled(event) {
            console.log("appinstalled", event);
        });

        async function install(event) {
            if (window.deferred_install_event) {
                deferred_install_event.prompt();
                const choice = await deferred_install_event.userChoice;
                console.log(choice);

                const app = document.getElementById("app");
                window.removeEventListener("beforeinstallprompt", onbeforeinstallprompt);
                app.removeEventListener("click", install);
                app.removeEventListener("tap", install);
                if (choice.outcome === "accepted") {
                    // show_install_button.value = false;
                    console.log("installed");
                } else {
                    console.log("canceled");
                    set_cookie("installation_canceled", "true", 3);
                }
            }
        }

        function onbeforeinstallprompt(deferred_install_event) {
            console.log("beforeinstallprompt", deferred_install_event);
            deferred_install_event.preventDefault();
            window.deferred_install_event = deferred_install_event;
            const install_delay_trigger = 10000;
            setTimeout(() => {
                const app = document.getElementById("app");
                app.addEventListener("click", install);
                app.addEventListener("tap", install);
            }, install_delay_trigger);
        }

        const installation_canceled = get_cookie("installation_canceled");
        if (installation_canceled === "true") {
            console.log("will be asked again to install in 3 days");
        } else {
            window.addEventListener("beforeinstallprompt", onbeforeinstallprompt);
        }

        if ("standalone" in navigator && navigator.standalone) {
            console.log("installed on iOS");
            installed_type.value = "iOS";
        } else if (matchMedia("(display-mode: standalone)").matches) {
            console.log("installed on Android or desktop");
            installed_type.value = "desktop";
        } else {
            console.log("rendered from browser");
            installed_type.value = "browser";
        }

        requestAnimationFrame(() => {
            navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({type: "check_online"});
        });

    } else {
        console.log("serviceWorker not supported");
    }
    const app = document.body;
    window.meta = import.meta;
    window.app_name = "translingo";

    if (import.meta.env.MODE === "test") {
        const app = document.getElementById("app");
        const component = (await import("./Test.jsx")).default;
        const el = await async_render(component, {$parent: app});
        console.log(el);
        mount(el);
    } else {
        fetch_vocabulary().then(() => console.log("vocabulary loaded"));
        await login(() => {
            start();
        });
    }

    if (/chrome/i.test(navigator.userAgent) && import.meta.env.MODE === "dev") {
        const content_css = await import("../../../translingo/content.css");
        const content = (await import("../../../translingo/content.js")).default;
        console.log(content_css, content);
    }
    requestAnimationFrame(() => {
        const token = localStorage.getItem("token");
        if (token) {
            window.postMessage({app: "translingo", type: "token", token});
        }
    });

    if ("ScreenOrientation" in window && window.ScreenOrientation.lock) {
        try {
            await window.ScreenOrientation.lock("portrait");
        } catch (error) {
            console.log("lock portrait", error);
        }
    }

    // PullToRefresh();
    window._ignited = false;

    async function speech(event) {
        try {
            const {target, currentTarget} = event;
            //
            console.log({target, currentTarget});
            //
            const $speak = target.closest(".speak");
            speak("hey");
            // if ($speak) {
            //     const {action, language, text} = {...$speak.dataset};
            //     speak(text);
            // }

        } catch (error) {
            console.log("ignite error", error);
        }
    }

    function ignite(event) {
        try {
            const {target, currentTarget, data, type} = event;
            //
            console.log({type, target, currentTarget, data});
            //


            // speak("Enjoy", 0.1);


            // const app = document.getElementById("igniting");
            // // const text = app.dataset.text;
            // if (window._ignited) {
            //
            // }

            // // const response = await play("test", "en-US", 1);
            // // if (response) {
            // console.log("played, remove ignite");
            //
            setTimeout(() => {

                // if (window._ignited) {
                const igniting = document.getElementById("igniting");
                const slogan = document.getElementById("slogan");

                igniting.style["display"] = "none";
                igniting.style["left"] = "-100%";
                igniting.classList.add("ignited");

                slogan.classList.add("ignited");

                igniting.removeEventListener("click", ignite);
                igniting.removeEventListener("touchstart", ignite);
                // } else {
                // ignite(event);
                // }

            }, 500);
        } catch (error) {
            console.log("ignite error", error);
        }
    }


    let _speechSynth;
    let _voices;
    const _cache = {};

    /**
     * retries until there have been voices loaded. No stopper flag included in this example.
     * Note that this function assumes, that there are voices installed on the host system.
     */

    function loadVoicesWhenAvailable(onComplete = () => {
        console.log("voice loaded");
    }) {
        _speechSynth = window.speechSynthesis;
        const voices = _speechSynth.getVoices();

        if (voices.length !== 0) {
            _voices = voices;
            onComplete();
        } else {
            return setTimeout(function () { loadVoicesWhenAvailable(onComplete); }, 100);
        }
    }

    const canvas = document.getElementById("canvas");
    canvas.addEventListener("contextmenu", function (event) {
        event.preventDefault();
    });

    window.addEventListener("online", () => {
        offline.value = false;
    });

    window.addEventListener("offline", () => {
        offline.value = true;
    });

    // setTimeout(() => {
    //     //     const app = document.body
    //     const wrapper = document.getElementById("wrapper");
    //     // wrapper.addEventListener("click", speech);
    //     // wrapper.addEventListener("touchstart", ignite);
    //
    //
    //     const igniting = document.getElementById("igniting");
    //     igniting.addEventListener("click", ignite);
    //     // igniting.addEventListener("touchstart", ignite);
    //     loadVoicesWhenAvailable();
    // }, 100);
    // let hasEnabledVoice = false;

    // window.addEventListener("touchstart", (event) => {
    //     console.log(event, hasEnabledVoice);
    //     if (hasEnabledVoice) {
    //         return;
    //     }
    //     const lecture = new SpeechSynthesisUtterance("hello");
    //     lecture.volume = 0;
    //     speechSynthesis.speak(lecture);
    //     hasEnabledVoice = true;
    // });


    // const el = await async_render(component, {$parent: app});
    // mount(el, app);
};


if (document.readyState !== "loading") {
    onReady().then(() => console.log("ready"));
} else {
    add_event_listener(document, "DOMContentLoaded", onReady);
}

// initSQLite("/chinook.db").then(result => {
//     const tables = result.rows.filter(row => row.type === "table");
//     const schema = {
//         label: "SQLite (in memory)",
//         img: "sqlite.gif",
//         children: tables.map(row => ({label: row.name, type: row.type}))
//     };
//     console.log(schema);
//     trees.value[0].value = schema;
// }).catch(console.error);

// const safer_plot = (Plot, F1_data, options) => {
//     const code = `[Plot.plot(${options}), document]`;
//     const f = safer(code, ["Plot", "F1_data"]);
//     return f(Plot, F1_data);
// };

// add_event_listener(window, "message", (event) => {
//     const iframe_svg = document.getElementById("iframe_svg");
//     const {id, html} = event.projects;
//     if (iframe_svg && id) {
//         console.log(html);
//         iframe_svg.innerHTML = html;
//     }
// });
//
// add_event_listener(window, "selectstart", function (evt) {
//     return (evt.target.contentEditable === "true");
// });
//
