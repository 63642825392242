import {promisify_request} from "./promisify.js";

export const delete_database = async (db) => {
    const w = window;
    const indexedDB = w.indexedDB || w.mozIndexedDB || w.webkitIndexedDB || w.msIndexedDB || w.shimIndexedDB;
    return await promisify_request(indexedDB.deleteDatabase(db));
};

export async function IndexedDB(dbName, collNames = [], mode = "readwrite", options) {

    // const objectStore = await getObjectStore(dbName, collName);
    const w = window;
    const indexedDB = w.indexedDB || w.mozIndexedDB || w.webkitIndexedDB || w.msIndexedDB || w.shimIndexedDB;
    const db = await getDB();

    async function openDB({version, createObjectStore = undefined}) {
        return new Promise(((resolve, reject) => {
            console.log({type: "openDB", dbName, version, createObjectStore});
            const dbRequest = version ? indexedDB.open(dbName, version) : indexedDB.open(dbName);
            let result = undefined;
            dbRequest.onupgradeneeded = e => {
                console.log({type: "onupgradeneeded", e});
                const db = e.target.result;
                if (createObjectStore) {
                    result = createObjectStore(db);
                }
            };
            dbRequest.onsuccess = e => {
                const db = e.target.result;
                console.log({type: "onsuccess", e});
                if (result) {
                    resolve({db, result});
                } else {
                    resolve({db});
                }
            };
            dbRequest.onerror = e => {
                console.log({type: "onerror", e});
                reject(e.target);
            };
        }));
    }

    let start;
    let _used_time;

    async function getDB() {
        let {db} = await openDB({dbName});
        console.log("db", db);
        const createObjectStore = (collections) => (_db) => {
            if (options) {
                collections.forEach(collName => _db.createObjectStore(collName, options));
            } else {
                collections.forEach(collName => _db.createObjectStore(collName));
            }
        };

        const not_exist_collections = collNames.filter(collName => {
            return !db.objectStoreNames.contains(collName);
        });
        console.log(db.objectStoreNames);
        if (not_exist_collections.length === 0) {
            // it's already existed, nothing need to be done
        } else {
            db.close();
            const response = await openDB({dbName, version: db.version + 1, createObjectStore: createObjectStore(not_exist_collections)});
            db = response.db;
        }
        return db;
    }

    function genKey(date = Date.now()) {
        return `${date.toString(36)}-${Math.random().toString(36).substring(2)}`;
    }

    async function getObjectStore(dbName, collName, mode = "readwrite", options) {
        // objectStore need be open in a transaction
        // and the transaction will be auto committed once we either add or get something
        // from the objectStore
        const transaction = db.transaction(collName, mode);
        transaction.onerror = console.log;
        return transaction.objectStore(collName);
    }

    async function put(collName, docKey, data, options) {
        const op_start = Date.now();
        const objectStore = await getObjectStore(dbName, collName);
        const request = objectStore.put(data, docKey || genKey());
        const resp = await promisify_request(request);
        _used_time += (Date.now() - op_start);
        return resp.result;
    }

    async function get(collName, docKey) {
        const op_start = Date.now();
        const objectStore = await getObjectStore(dbName, collName);
        const request = objectStore.get(docKey);
        const resp = await promisify_request(request);
        _used_time += (Date.now() - op_start);
        return resp.result;
    }

    async function get_put_if_not_exist(collName, docKey, defaultValue) {
        const result = await get(collName, docKey);
        if (result) {
            return result;
        } else {
            await put(collName, docKey, defaultValue);
            return defaultValue;
        }
    }

    async function _delete(collName, docKey) {
        const op_start = Date.now();
        const objectStore = await getObjectStore(dbName, collName);
        const request = objectStore.delete(docKey);
        const resp = await promisify_request(request);
        _used_time += (Date.now() - op_start);
        return resp.result;
    }

    return {
        elapsed() {
            if (start) {
                return ~~((Date.now() - start) / 1000);
            } else {
                return undefined;
            }
        },
        start_clock() {
            start = Date.now();
            _used_time = 0;
        },
        used_time() {
            return ~~(_used_time / 1000);
        },
        get,
        get_put_if_not_exist,
        put,
        put_if_not_exist: async (collName, key, data, options) => {
            if (!await get(collName, key)) {
                await put(collName, key, data, options);
            }
        },
        delete: _delete,

        clear: async (collName) => {
            const objectStore = await getObjectStore(dbName, collName);
            const request = objectStore.clear();
            const resp = await promisify_request(request);
            return resp.result;
        },
        count: async (collName) => {
            const objectStore = await getObjectStore(dbName, collName);
            const request = objectStore.count();
            const resp = await promisify_request(request);
            return resp.result;
        },
    };
    //
    // const getAll = async (dbName, collName, range) => {
    //     // field > value
    //     // field < value
    //     // field >= value
    //     // field <= value
    //     // value1 <= field <= value2
    //
    //     const objectStore = await getObjectStore(dbName, collName);
    //     if (range) {
    //         if (range.index) {
    //             const index = objectStore.index(range.index);
    //             const response = await promisify_request(index.getAll(range.gen()));
    //             return response.result;
    //         } else {
    //             const response = await promisify_request(objectStore.getAll(range.gen()));
    //             return response.result;
    //         }
    //     } else {
    //         const response = await promisify_request(objectStore.getAll());
    //         return response.result;
    //     }
    // };
    //
    // const R = (index) => {
    //     const conditions = {};
    //     const result = {
    //         ge: v => (conditions["ge"] = v, result),
    //         gt: v => (conditions["gt"] = v, result),
    //         le: v => (conditions["le"] = v, result),
    //         lt: v => (conditions["lt"] = v, result),
    //         index,
    //         gen: () => {
    //             const n = Object.keys(conditions).length;
    //             if (n === 1) {
    //                 if (conditions["ge"]) {
    //                     return IDBKeyRange.lowerBound(conditions["ge"]);
    //                 } else if (conditions["gt"]) {
    //                     return IDBKeyRange.lowerBound(conditions["gt"], true);
    //                 } else if (conditions["le"]) {
    //                     return IDBKeyRange.upperBound(conditions["le"]);
    //                 } else if (conditions["lt"]) {
    //                     return IDBKeyRange.lowerBound(conditions["lt"], true);
    //                 } else {
    //                     console.log("wrong conditions", conditions);
    //                 }
    //             } else if (n === 2) {
    //                 if (conditions.ge && conditions.le) {
    //                     return IDBKeyRange.bound(conditions.ge, conditions.le);
    //                 } else if (conditions.gt && conditions.lt) {
    //                     return IDBKeyRange.bound(conditions.gt, conditions.lt, true, true);
    //                 } else if (conditions.gt && conditions.le) {
    //                     return IDBKeyRange.bound(conditions.gt, conditions.le, true, false);
    //                 } else if (conditions.ge && conditions.lt) {
    //                     return IDBKeyRange.bound(conditions.ge, conditions.lt, false, true);
    //                 } else {
    //                     console.log("wrong conditions", conditions);
    //                 }
    //             } else {
    //                 console.log("wrong conditions", conditions);
    //             }
    //         }
    //     };
    //     return result;
    // };
    //
    // const P = R(undefined);

// window.IndexedBase = IndexedBase;
// window.IB = {
//     put, genKey, get, clear, count, getAll, R, P
// };
//
// console.log("hello window.indexedDB");

}

export default IndexedDB;