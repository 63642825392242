import {async_render} from "./nano.jsx";
import {ref} from "./reactive.jsx";

export function NotExist(props, children) {

    return (
        <div id="app">
            <p>The page you access is not exist</p>
        </div>
    );
}


export default async function Router(routes) {
    async function onpopstate(event) {
        // const $dialog = document.getElementById("dialog");
        // $dialog.classList.remove("open");
        // $dialog.close();


        await location_handler(component, props);
    }

    async function location_handler(component, props) {

        if (component && props) {

        } else {
            const {pathname, hash, href} = window.location;
            console.log({pathname, hash});
            component = routes[pathname] || routes[404] || NotExist;

            const params = new URLSearchParams(window.location.search);
            // const props = Object.fromEntries([...params].map(([key, value]) => [key, ref(value)]));
            props = Object.fromEntries([...params]);
        }
        console.log(props);
        const $el = await async_render(component, props);
        const app = document.getElementById("app");
        app.replaceWith($el);
    }

    // const _pushState = history.pushState;
    // history.pushState = function (data, unused, href) {
    //     _pushState(data, unused, href);
    //     location_handler();
    // };


    window.onpopstate = onpopstate;
    // window.route = route;

    // await location_handler();
    return location_handler;
}