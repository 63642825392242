export default function get_text(word, language) {
    const content = word[language];
    if (Array.isArray(content)) {
        return content.flat().join(" ");
    } else if (content.hasOwnProperty("text")) {
        return content.text;
    } else {
        return content;
    }
}

const [form1, pronunciation, examples, morphemes, syllables, comparative, superlative, form2] = [];

export function Word([english, chinese]) {

}