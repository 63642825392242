const _log =
    (func, withStack = false) =>
        (message) => {

            const name = import.meta.url.split("/").at(-1);

            // const caller = stack.split("\n")[2].trim().split(" ")[1];
            if (message && message.start) {
                const elapsed = Date.now() - message.start;
                if (withStack) {
                    func({name, ...message, stack: new Error().stack, elapsed});
                } else {
                    func({name, ...message, elapsed});
                }
            } else {
                if (withStack) {
                    func({name, ...message, stack: new Error().stack});
                } else {
                    func({name, ...message});
                }
            }
        };


const [level_trace, level_debug, level_info, level_warn, level_error] = [0, 1, 2, 3, 4];
let log_level = level_debug;
// let log_level = level_info;
// let log_level = level_warn;
// let log_level = level_error;
let enabled_modules = undefined;

export default function logger(meta) {
    const module = meta.url.split("/").at(-1);
    // debug: process.env.NODE_ENV === 'development' ? _log(console.log) : doNothing,

    return {
        trace: (...msg) => {
            if (log_level <= level_trace && (enabled_modules === undefined || enabled_modules.includes(module))) {
                console.trace(module, ...msg);
            }
        },
        debug: (...msg) => log_level <= level_debug && console.debug(module, ...msg),
        info: (...msg) => log_level <= level_info && console.info(module, ...msg),
        warn: (...msg) => log_level <= level_warn && console.warn(module, ...msg),
        error: (...msg) => log_level <= level_error && console.error(module, ...msg),
        level_trace,
        level_debug,
        level_info,
        level_warn,
        level_error,
        set_level: (level) => log_level = level,
        enable_modules: modules => enabled_modules = modules
    };
};
