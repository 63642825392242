import {ref} from "nano/reactive.jsx";
import Projectile from "./game/Projectile.js";

export function RefObject(clazz) {
    return function (src) {
        const result = ref();
        const obj = new clazz();
        obj.onload = () => {
            result.value = obj;
        };
        obj.onerror = (error) => {
            console.log(error);
        };
        obj.src = src;
        return result;
    };
}

export const RefImage = RefObject(Image);
export const RefAudio = RefObject(Audio);
