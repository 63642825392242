export const max_translate_words = 5;
export const max_translate_characters = 140;

const dev = /(localhost)|(192)/.test(window.location.hostname) || localStorage.getItem("dev");
console.log(dev, window.location.hostname);
export const per_page_words = dev ? 2 : 5;
export const game_words_interval = dev ? 4 : 10;
export const max_game_time = (dev ? 0.1 : 3) * 60 * 1000; // testing 10 seconds :-)

// export const per_page_words = 2;
// export const game_words_interval = 4;
// export const max_game_time = 0.1 * 60 * 1000; // testing 10 seconds :-)


//   0ms unselected => selected
//   0ms selected   => matched
// matched    => disabled
export const change_to_disabled_delay = 400;
export const goto_next_page_delay = change_to_disabled_delay + 300;
export const batch_per_level = 3;
const levels = {green: 1, blue: 2, red: 3, black: 4};

export function get_batch(level) {
    return levels[level] || 1;
}