export default function Player(options) {
    const me = {
        max_dx: 3,
        health: 10,
        dx: 10,
        dy: 10,
        ...options,
        update,
        draw,
        clear,
    };

    function update({clientX, clientY}) {
        if (clientX) {
            // me.dx = Math.min(me.dx + me.a, me.max_dx);
            if (clientX > me.x) {
                me.x = Math.min(me.x + me.dx, clientX);
            } else {
                me.x = Math.max(me.x - me.dx, clientX);
            }
        }
        if (clientY) {
            // me.dx = Math.min(me.dx + me.a, me.max_dx);
            if (clientY > me.y) {
                me.y = Math.min(me.y + me.dy, clientY);
            } else {
                me.y = Math.max(me.y - me.dy, clientY);
            }
        }
        me.left = me.x - 10;
        me.right = me.x - 10;
        me.top = me.y - 10;
        me.bottom = me.y + 10;
    }

    function draw(ctx) {
        ctx.beginPath();
        if (me.img.value) {
            ctx.drawImage(me.img.value, me.x - me.img.value.width / 2, me.y - me.img.value.height / 2);
        } else {
            ctx.fillStyle = me.color;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
    }

    function clear(ctx) {
        // ctx.font = `20px "Press Start 2P", monospace`;
        ctx.beginPath();
        if (me.img.value) {
            // ctx.fillStyle = me.background;
            // ctx.fillRect(me.x - me.img.width / 2, me.y - me.img.height / 2, me.img.width, me.img.height);
            // ctx.fill();
        } else {
            ctx.fillStyle = me.background;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
    }


    return me;
}