import For from "nano/For.jsx";
import IconMessageSquare from "solidjs-feather/IconMessageSquare.jsx";
import IconChevronsLeft from "solidjs-feather/IconChevronsLeft.jsx";
import IconChevronsRight from "solidjs-feather/IconChevronsRight.jsx";
import IconTrash2 from "solidjs-feather/IconTrash2.jsx";
import {computed, ref} from "nano/reactive.jsx";
import {get_lang_flag} from "nano/unicode.js";
import Swipe from "./swipe.js";
import play, {speak} from "./play.js";
import {translation_history, update_history, target_languages} from "./store.jsx";
// import {route} from "nano/Router.jsx";
import api from "./api.jsx";
import "./WalkThrough.css";
import {route} from "./route.jsx";
import Toolbar from "./Toolbar.jsx";
import UseSwipe from "./UseSwipe.jsx";
import {Render} from "nano/nano.jsx";
import {clone_object} from "nano/lib/_.js";
import {goto_next_page_delay} from "./config.jsx";
import flags from "./flags.js";
import get_text from "./word.js";

const mata_info = new Set(["source_language"]);
const symbol_regex = /[,;&…\.\(\)（、…）]/g;
const lemma_regex = /[a-zA-Z]+\./g;

function remove_symbols(s) {
    return s.replaceAll(/^\.+|\.+$/g, "").replaceAll(lemma_regex, " ").replaceAll(symbol_regex, " ");
}

export default function WalkThroughContent(props, children) {
    const {word, languages_visible} = props;

    const entries = Object.entries(word).flatMap(([language, value]) => {
        const text = get_text(word, language);
        const result = [[language, text]];
        if (value.pronunciation) {
            // for pronunciation, we will still use the text to speak
            result.push([language, value.pronunciation, text]);
        }
        (value.examples || []).forEach(example => result.push([language, example]));

        return result;
    });


    console.log(props);
    return (
        <div class="content">
            <For _each={entries}>{function show([language, text, source_text], i) {

                const visible = languages_visible[language];


                // console.log(translations.value, translation, i);
                function play_sound(event) {
                    console.log(event.type, text, language);
                    if (language && typeof text === "string") {
                        const playable = remove_symbols(source_text || text);
                        play(playable, language);
                        // speak(playable);
                    }
                }


                const result = (
                    <div onclick={play_sound}
                         class="speak"
                         data-action="speak"
                         data-language={language}
                         data-text={text}
                    >
                        <span class="language_icon small">{flags[language]}</span>
                        <span class={{language: true, blur: !visible}}>{text}</span>
                    </div>);
                return result;
            }}</For>
        </div>);

}