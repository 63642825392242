import Router from "nano/Router.jsx";
import Translator from "./Translator.jsx";
import WalkThrough from "./WalkThrough.jsx";
import MatchMaking from "./MatchMaking.jsx";
import Menu from "./Menu.jsx";
import Editor from "./Notebook.jsx";
import Debug from "./debug.jsx";
import Learn from "./Learn.jsx";
import Notebook from "./Notebook.jsx";
import {SyllableMaster} from "./Slicer.jsx";
import {cihuile} from "./store.jsx";

function NotExist(props, children) {

    return (
        <div id="app">
            <p>The page you access is not exist</p>
        </div>
    );
}

const routes = cihuile ? {"/": Learn} : {
    // "/": Translator,
    "/": Learn,
    // "/": Menu,
    // "/": Notebook,
    // "/": WalkThrough,
    // "/": SyllableMaster,
    "/syllable-master": SyllableMaster,
    "/translator": Translator,
    "/menu": Menu,
    "/walk-through": WalkThrough,
    "/match-making": MatchMaking,
    "/editor": Editor,
    "/notebook": Notebook,
    "/learn": Learn,
    "/debug": Debug,
};

const router = await Router(routes);

export async function start() {
    router();
}

export function route(href, hook = undefined, data = {}) {
    return async function (event = {}) {
        const {target, currentTarget, type} = event;
        if (hook) {
            hook();
        }
        event.preventDefault && event.preventDefault();
        event.stopPropagation && event.stopPropagation();
        console.log({data, href, type, target, currentTarget});
        const url = new URL(`${window.location.origin}${href}`);
        const {pathname, search} = url;
        const component = routes[pathname] || routes[404] || NotExist;

        const params = new URLSearchParams(search);
        // const props = Object.fromEntries([...params].map(([key, value]) => [key, ref(value)]));
        const props = Object.fromEntries([...params]);

        await router(component, props);
        window.history.pushState(data, "", href);
    };
}
