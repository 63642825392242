let context = [];
console.log("reactive");

function cleanup(observer) {
    // console.log("cleanup");
    for (const dep of observer.dependencies) {
        dep.delete(observer);
    }
    observer.dependencies.clear();
}

function subscribe(observer, subscriptions) {
    // console.log("subscribe");
    subscriptions.add(observer);

    observer.dependencies.add(subscriptions);
}

export function is_ref(v) {
    return typeof v === "object" && v.__type === "ref";
}

export function ref(value, $el, name) {
    const subscriptions = new Set();

    function remove(item, index) {
        if (Array.isArray(value)) {
            // check d != v, if they are the same reference
            const new_value = value.filter((d, i) => item != d);
            console.log({item, index, value, new_value});
            target.value = new_value;
        } else {
            throw {error: "append value to ref only make sense when it's array", value, v};
        }
    }

    function append(item_value) {
        if (Array.isArray(value)) {
            target.value = [...target.value, item_value];
        } else {
            throw {error: "append value to ref only make sense when it's array", value, v: item_value};
        }
    }

    // append a raw value as ref
    function append2(v) {
        append(ref(v));
    }

    function set(k, v) {
        if (typeof value === "object") {
            target.value = {...target.value, [k]: v};
        } else {
            throw {error: "append value to ref only make sense when it's array", value, k, v};
        }
    }

    // set a raw value, will set as ref if not exist
    function set2(k, v) {
        if (typeof value === "object") {
            const current = value[k];
            if (is_ref(current)) {
                current.value = v;
            } else {
                target.value = {...target.value, [k]: ref(v)};
            }
        } else {
            throw {error: "append value to ref only make sense when it's array", value, k, v};
        }
    }

    const target = {
        get value() {
            const observer = context[context.length - 1];
            if (observer) {
                subscribe(observer, subscriptions);
            }
            return value;
        },

        set value(newValue) {
            value = newValue;
            for (const sub of [...subscriptions]) {
                sub.execute();
            }
        },
        append,
        append2,
        set,
        set2,
        clone() {
            return computed(() => target.value);
        },
        remove,
        init: value,
        __type: "ref"
    };

    if ($el) {
        target.$el = $el;
    }
    return target;
}


//
//
// export function _ref2(value, value2) {
//     const subscriptions = new Set();
//
//     const target = {
//         get value() {
//             const observer = context[context.length - 1];
//             if (observer) {
//                 subscribe(observer, subscriptions);
//             }
//             return value;
//         },
//
//         set value(new_value) {
//             value = new_value;
//             for (const sub of [...subscriptions]) {
//                 sub.execute();
//             }
//         },
//         get value2() {
//             return value2;
//         },
//         set value2(v) {
//             value2 = v;
//         },
//         init: value,
//         __type: "ref"
//     };
//
//     return target;
// }

export function ref2(value, $el, name) {
    const wrap_value = Array.isArray(value) ? value.map((v, i) => ref(v)) : value;
    return ref(wrap_value, $el);
}

export function effect(fn, name) {
    const effect = {
        execute() {
            cleanup(effect);
            context.push(effect);
            fn();
            context.pop();
        },
        dependencies: new Set()
    };
    effect.execute();
}

export function untrack(fn) {
    const prev_context = context;
    context = [];
    const result = fn();
    context = prev_context;
    return result;
}

export function watch(r, fn) {
    let old_value;
    effect(() => {
        // console.log({new_value: r.value, old_value});
        try {
            const new_value = r.value
            untrack(() => fn(new_value, old_value));
            old_value = new_value;
        } catch (error) {
            console.log(r, fn, error);
        }
        // old_value = r.value;
    });
}

export function computed(fn, name) {
    const v = ref();
    effect(() => {
        v.value = fn();
    }, name);

    return v;
}

export function clone(r) {
    return computed(() => r.value);
}

export function clear_ref(v, timeout = 30 * 1000) {
    const r = ref(v);
    watch(r, function clear(new_value, old_value) {
        setTimeout(() => {
            r.value = undefined;
        }, timeout);
    });
    return r;
}