export default {
    arabic: "🧞",
    chinese: "🇨🇳",
    english: "🇬🇧",
    french: "🇫🇷",
    russian: "🇷🇺",
    japanese: "🇯🇵",
    spanish: "🇪🇸",
    swedish: "🇸🇪"
};
