console.log("Meteor");
export default function Meteor(options) {
    const me = {
        d_alpha: -0.2,
        color: "white",
        ...options,
        update,
        draw
    };

    function update() {
        me.prev_x = me.x;
        me.prev_y = me.y;
        me.x = me.x + me.dx;
        me.y = me.y + me.dy;
        me.alpha += me.d_alpha;
    }

    function draw(ctx) {
        ctx.beginPath();
        ctx.strokeStyle = `hsla(25, ${Math.random() * 60}%, ${Math.random() * 60}%, ${me.alpha})`;
        ctx.strokeWidth = me.radius;
        ctx.moveTo(me.x, me.y);
        ctx.lineTo(me.prev_x, me.prev_y);
        ctx.stroke();
        ctx.restore();
    }

    return me;
}