import {computed, ref} from "nano/reactive.jsx";
import api from "./api.jsx";
import {delete_database, IndexedDB} from "nano/lib/IndexedDB.js";

export const deferred_install = ref();
export const installed_type = ref("browser");

export const translation_history = ref([]);
export const history_empty = computed(() => {
    return !(translation_history.value?.length);
});

export const meta = ref({
    languages: ["english", "chinese"],
    selected_languages: ["english", "chinese"],
    mother_tongues: ["chinese"],
    fluent_languages: [],
    learning_languages: ["english"]
});
console.log(meta);
export const cache_languages = localStorage.getItem("languages`");
export const target_languages = computed(() => cache_languages ? JSON.parse(cache_languages) : meta.value.languages);

export async function update_history() {
    const history = await api.get("/api/history");
    if (history && Array.isArray(history)) {
        translation_history.value = [];
        translation_history.value = history;
        // requestAnimationFrame(() =>translation_history.value = history);
    } else {
        console.log("history", history);
    }
}

export const is_mobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
console.log(import.meta.env);
export const cihuile = import.meta.env.MODE === "cihuile";
export const offline = ref(false);
export const db = await IndexedDB("translingo", [
    "arabic",
    "english",
    "french",
    "german",
    "japanese",
    "russian",
    "spanish",
    "swedish",
]);

window.db = db;

export async function fetch_vocabulary(language) {
    try {
        if (offline.value) {
            return 0;
        } else {
            const cache = await db.get(language, 1);
            if (cache) {
                return 1;
            } else {
                const [lang, vocabulary] = await api.get("/api/vocabulary", {language});
                for (let level = 0; level < vocabulary.length; level += 1) {
                    await db.put(lang, level + 1, vocabulary[level]);
                }

                return vocabulary.length;
            }
        }
    } catch (error) {
        return `not able to fetch, might be offline`;
    }
}

