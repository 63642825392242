export default function Pointer() {
    const me = {
        clientX: undefined,
        clientY: undefined,
        ontouchstart,
        ontouchmove,
        ontouchend,
        onmousedown,
        onmousemove,
        onmouseup
    };


    function move(touch) {
        const {clientX, clientY} = touch;
        // console.log([~~clientX, ~~clientY]);
        me.clientX = clientX;
        me.clientY = clientY;
    }

    function ontouchstart(event) {
        console.log();
        // event.preventDefault();
        const touches = [...event.touches];
        if (touches.length === 1) {
            move(touches[0]);
        }
    }

    function ontouchend(event) {
        move({});
    }


    function ontouchmove(event) {
        // event.preventDefault();
        const touches = [...event.touches];
        if (touches.length === 1) {
            move(touches[0]);
        }
    }

    function onmousedown(event) {
        // event.preventDefault();
        move(event);
        window.addEventListener("mousemove", onmousemove);
        window.addEventListener("mouseup", onmouseup);
    }

    function onmousemove(event) {
        move(event);
    }


    function onmouseup(event) {
        // event.preventDefault();
        move({});
        window.removeEventListener("mousemove", onmousemove);
        window.removeEventListener("mouseup", onmouseup);

    }

    return me;
}

