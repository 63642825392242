import play from "../play.js";
import Particle from "./Particle.js";
import {rect_collide} from "./Collission.js";
import {turn_down_volume, turn_up_volume} from "./Audio.js";

export default function Word(options) {
    const {word, language, use_language} = options;

    const {text, syllables = [], morphemes = []} = word[language];
    // morphemes using [morpheme, english meaning, chinese meaning]
    const parts = morphemes.length > 0 ? morphemes.map(morpheme => morpheme[0]) : syllables;

    const me = {
        x: (Math.random() * window.innerWidth * 0.9 + 20),
        y: 0,
        dx: 0,
        dy: 1,
        friction: 1,
        a: 0,
        finished: false,
        height: 20,
        ...options,
        text,
        split: 0,
        update, clear, draw,
        resolve_collision
    };

    let played = undefined;


    function update(ctx) {
        me.dy = (me.dy + me.a) * me.friction;
        me.x += me.dx;
        me.y += me.dy;

        // ctx.font = `20px "Press Start 2P", monospace`;
        // const metrics = ctx.measureText(me.text);
        // me.metrics = metrics;
        // const half_width = metrics.width / 2;
        const half_width = 20 * me.text.length / 2;
        me.left = me.x - half_width;
        me.right = me.x + half_width;
        me.top = me.y - 10;
        me.bottom = me.y + 10;

    }

    function add_explosion_particles(xy, particles) {
        Array(5).fill(0).forEach(_ => {
            const particle = new Particle({
                x: xy.x,
                y: xy.y,
                dx: (Math.random() - 0.5) * me.explosion_speed,
                dy: (Math.random() - 0.5) * me.explosion_speed,
                radius: Math.random() * me.explosion_radius,
                color: me.color,
                alpha: 1
            });
            particles.push(particle);
        });
    }

    function get_split_text() {
        if (parts.length > 1) {
            let result = "";
            for (let i = 0; i < parts.length; i++) {
                result += parts[i];
                if (i < me.split) {
                    result += " ";
                }
            }
            return result;
        } else {
            return me.text;
        }
    }


    function resolve_collision({ctx, projectiles, player, particles}) {

        for (let i = projectiles.length - 1; i >= 0; i -= 1) {
            const projectile = projectiles[i];

            const collide_y = projectile.y > me.y && projectile.y < me.y + me.height;
            const collide_x = projectile.x > me.left && projectile.x < me.right;
            if (collide_x && collide_y) {
                projectile.collide = true;
                projectiles.splice(i, 1);
                add_explosion_particles(projectile, particles);
                me.split += 1;
                me.text = get_split_text();
                const positions = word[use_language];
                // if there is still no meaning set yet and there is translations
                if (!me.meaning && positions.length > 0) {
                    const position_index = ~~(Math.random() * positions.length);
                    const [pos, meanings = []] = positions[position_index];
                    const meaning_index = ~~(Math.random() * meanings.length);
                    me.meaning = meanings[meaning_index];
                }

                if (me.split >= parts.length) {
                    if (me.played && !me.finished) {
                        setTimeout(() => {
                            me.finished = true;
                        }, 400);
                    } else {
                        turn_down_volume();
                        play(text, language, 1, turn_up_volume);
                        me.played = true;
                    }
                }
            }
        }


        if (me.finished) {
            // if already finished, nothing need be done
        } else if (me.y > innerHeight) {
            player.health -= 1;
        } else if (rect_collide(me, player)) {
            add_explosion_particles(player, particles);
            player.health -= 1;
            me.finished = true;
        }
    }

    function clear(ctx) {

        // ctx.font = `20px monospace`;
        // ctx.fillStyle = me.background;
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.fillText(me.text, me.x, me.y);
        // if (me.meaning) {
        //     ctx.fillText(me.meaning, me.x, me.y + 20);
        // }
    }

    function draw(ctx) {
        ctx.font = `20px monospace`;

        ctx.fillStyle = me.color;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(me.text, me.x, me.y);
        if (me.meaning) {
            ctx.fillText(me.meaning, me.x, me.y + 20);
        }
        // ctx.beginPath();
        // ctx.strokeStyle = "blue"; // Line color
        // ctx.lineWidth = 5;
        //
        // ctx.moveTo(me.left, me.y);
        // ctx.lineTo(me.left, me.y + me.height);
        // ctx.stroke();
        //
        // ctx.moveTo(me.right, me.y);
        // ctx.lineTo(me.right, me.y + me.height);
        // ctx.stroke();

        // ctx.beginPath();
        // ctx.moveTo(
        //     me.x - me.metrics.actualBoundingBoxLeft,
        //     me.y - me.metrics.actualBoundingBoxAscent
        // );
        // ctx.lineTo(
        //     me.x + me.metrics.actualBoundingBoxRight,
        //     me.y - me.metrics.actualBoundingBoxAscent
        // );
        // ctx.lineTo(
        //     me.x + me.metrics.actualBoundingBoxRight,
        //     me.y + me.metrics.actualBoundingBoxDescent
        // );
        // ctx.lineTo(
        //     me.x - me.metrics.actualBoundingBoxLeft,
        //     me.y + me.metrics.actualBoundingBoxDescent
        // );
        // ctx.closePath();
        // ctx.stroke();

        // ctx.fillStyle = `#889`;
        // ctx.fillText(`GAME ENDED. SCORE: 100`, 500, 100);
        // ctx.fillText(restart, left, window.innerHeight / 2 + 20);
    }

    return me;
}
