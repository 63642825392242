import {computed, ref, watch} from "nano/reactive.jsx";
import For from "nano/For.jsx";
import {change_to_disabled_delay} from "./config.jsx";
import {clone_object} from "nano/lib/_.js";
import play, {speak} from "./play.js";
import get_text from "./word.js";

function shuffler(random = Math.random) {
    return function shuffle(array, i0 = 0, i1 = array.length) {
        let m = i1 - (i0 = +i0);
        while (m) {
            const i = random() * m-- | 0, t = array[m + i0];
            array[m + i0] = array[i + i0];
            array[i + i0] = t;
        }
        return array;
    };
}


export default function Matcher(props, children) {
    const {words = [], active, on_finish, page, canvas, ctx, left_language, right_language} = props;
    console.log(props);
    const left_words = shuffler()(clone_object(words));
    const right_words = shuffler()(clone_object(words));
    const left_selection = ref();
    const right_selection = ref();

    function draw() {
        console.log("draw");
        try {
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const radius = 50;
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            ctx.fillStyle = "blue";
            ctx.fill();
        } catch (error) {
            console.log(error);
        }
        requestAnimationFrame(draw);
    }

    // requestAnimationFrame(draw);

    function all_corrected() {
        for (let i = left_words.length - 1; i >= 0; i -= 1) {
            if (!left_words[i].corrected || !right_words[i].corrected) {
                return false;
            }
        }
        return true;
    }

    let start = undefined;

    function ondblclick(word, language, i) {
        return function (event) {
            console.log(word, language, i);
            const text = word[language];
            play(text, language);
        };
    }

    function onclick(word, selection, i) {
        return function (event) {
            if (word.corrected) {

            } else {
                selection.value = selection.value === i ? undefined : i;

                if (left_selection.value !== undefined && right_selection.value !== undefined) {
                    const left_word = left_words[left_selection.value];
                    const right_word = right_words[right_selection.value];
                    if (left_word.i === right_word.i) {
                        start = Date.now();
                        const $left = document.getElementById(`${page}_left_word_${left_selection.value}`);
                        const $right = document.getElementById(`${page}_right_word_${right_selection.value}`);

                        $left.classList.remove("disabled");
                        $right.classList.remove("disabled");

                        console.log(`0ms word ${left_word} is match`, [...$left.classList], [...$right.classList]);
                        left_word.corrected = true;
                        right_word.corrected = true;

                        left_selection.value = undefined;
                        right_selection.value = undefined;

                        requestAnimationFrame(() => start_animate_correct($left, $right));

                        if (all_corrected()) {
                            console.log(`${Date.now() - start}ms all word matched`, [...$left.classList], [...$right.classList]);
                            on_finish(start);

                        }
                        return true;
                    }
                }

            }

        };
    }

    function start_animate_correct($left, $right) {

        $left.classList.remove("selected");
        $right.classList.remove("selected");
        $left.classList.add("matched");
        $right.classList.add("matched");
        console.log(`${Date.now() - start}ms matched`, [...$left.classList], [...$right.classList]);

        setTimeout(() => {
            console.log(`${Date.now() - start}ms remove matched`, [...$left.classList], [...$right.classList]);
            $left.classList.remove("matched");
            $right.classList.remove("matched");
            console.log(`${Date.now() - start}ms add disabled`, [...$left.classList], [...$right.classList]);
            $left.classList.add("disabled");
            $right.classList.add("disabled");
        }, change_to_disabled_delay);
    }


    return (
        <div class="panes">
            <div class="left">
                <For _each={left_words} name="left">{(word, left_word_index) => {
                    const selected = computed(() => {
                        const result = left_selection.value === left_word_index;
                        console.log({result, word, left_word_index});
                        return result;
                    });
                    console.log(word);
                    return (
                        <div class="word_container">
                            <div
                                class={{word: true, selected}}
                                onclick={onclick(word, left_selection, left_word_index)}

                                id={`${page}_left_word_${left_word_index}`}
                            >
                                <span>{get_text(word, left_language)}</span>
                            </div>
                        </div>
                    );
                }}</For>
            </div>
            <div class="right">
                <For _each={right_words} name="right">{(word, right_word_index) => {
                    console.log(word);
                    const selected = computed(() => {
                        const result = right_selection.value === right_word_index;
                        console.log({result, word, right_word_index});
                        return result;
                    });
                    return (
                        <div class="word_container">
                            <div
                                class={{word: true, selected}}
                                onclick={onclick(word, right_selection, right_word_index)}
                                id={`${page}_right_word_${right_word_index}`}>
                                <span>{get_text(word, right_language)}</span>
                            </div>
                        </div>
                    );
                }}</For>
            </div>
        </div>);
}
