import {route} from "./route.jsx";
import IconMessageSquare from "solidjs-feather/IconMessageSquare.jsx";
import IconGrid from "solidjs-feather/IconGrid.jsx";
import IconBookOpen from "solidjs-feather/IconBookOpen.jsx";
import IconLink2 from "solidjs-feather/IconLink2.jsx";
import "./Toolbar.css";

export default function (props, children) {
    const {type, hide, color = "black"} = props;

    console.log(props);

    function ontouchstarter(href) {

        return function ontouchstart(event) {
            console.log(event);
            event.preventDefault();
            route(href)(event);
        };
    }

    if (type === "translator") {
        return (
            <div class="toolbar">
                <div ontouchclick={route("/menu")}
                >
                    <IconGrid/>
                    {/*<span>Review</span>*/}
                </div>
            </div>
        );
    } else {
        return (
            <div class="toolbar" style={{color}} _hide={hide}>
                {/*<span onclick={route("/menu")}><IconGrid/></span>*/}
                <div ontouchclick={route("/translator")}>
                    <IconMessageSquare/>
                    {/*<span>Translate</span>*/}
                </div>
                <div ontouchclick={route("/menu")}>
                    <IconGrid/>
                    {/*<span>Review</span>*/}
                </div>
            </div>
        );
    }
}