import "./LanguageBar.css";
import For from "nano/For.jsx";
import {computed, ref} from "nano/reactive.jsx";

import {clone_object} from "nano/lib/_.js";
import {translation_history, update_history, target_languages} from "./store.jsx";

console.log("LanguageBar");
export default function LanguageBar(props, children) {
    const {selected_languages} = props;
    const languages = clone_object(target_languages.value);
    console.log(selected_languages);
    const hint = computed(() => {
        switch (selected_languages.value.length) {
            case 0:
                return "Pick two languages";
            case 1:
                return "Pick the second language";
            case 2:
                return " ";
            default:
                const n = selected_languages.value.length - 2;
                return `Unselect ${n} languages`;
        }
    });

    return (
        <div class="language_bar">
            <div class="languages">
                <For _each={languages}>{({language, icon}, i) => {
                    const selected = computed(() => selected_languages.value.includes(language));

                    function ontouchstart(event) {
                        event.preventDefault();
                        onclick(event);
                    }

                    function onclick(event) {
                        const languages = selected_languages.value;
                        console.log(languages, language);
                        if (languages.includes(language)) {
                            selected_languages.value = languages.filter(lang => lang !== language);
                        } else {
                            selected_languages.value = [...languages, language];
                        }
                    }

                    return <div class={{language: true, selected}} ontouchclick={onclick}>{icon}</div>;
                }}</For>
            </div>
            <div class="hint">{hint}</div>
        </div>
    );
}