import {ref} from "nano/reactive.jsx";


console.log("API");

export default API();

function API() {

    const result = {
        send_socket_message,
        get: invoke("get"),
        post: invoke("post")
    };

    function safe_params(obj) {
        const params = new URLSearchParams();
        Object.entries(obj).forEach(function set_props_if_has_value([name, value]) {
            if (value) {
                params.set(name, value);
            }
        });
        if (!obj.hasOwnProperty("token")) {
            params.set("token", localStorage.getItem("token") || "");
        }
        return params;
    }

    function invoke(method) {
        return async function (url, params = {}) {

            const full_url = `${url}?${safe_params(params).toString()}`;
            try {
                const response = await fetch(full_url, {
                    method,
                    headers: {"Accept-Encoding": "gzip, deflate, br"}
                });
                const json = await response.json();
                console.log(url, json);
                return json;
            } catch (error) {
                console.log(url, params, error);
            }
        };
    }

    let socket = undefined;

    let session = undefined;
    let index = 0;

    // let meta;
    //
    // function location_handler() {
    //     const {pathname, hash, href} = window.location;
    //     console.log({pathname, hash});
    //
    //     if (hash) {
    //         meta = JSON.parse(atob(hash.slice(1)));
    //         localStorage.setItem("meta", JSON.stringify(meta));
    //
    //         const no_hash_href = href.split("#")[0];
    //         if (no_hash_href !== href) {
    //             history.replaceState({}, document.title, no_hash_href);
    //         }
    //     } else {
    //         meta = JSON.parse(localStorage.getItem("meta") || "{}");
    //     }
    // }
    //
    // location_handler();

    async function blob_to_string(blob) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();

                reader.onload = function () {
                    // 'result' contains the string representation of the blob projects
                    const string = reader.result;
                    resolve(string);
                };

                reader.readAsText(blob);
            } catch (error) {
                reject(error);
            }
        });
    }

    function ArrayBuffer_to_string(buf) {
        const decoder = new TextDecoder("utf-8");
        return decoder.decode(buf);
    }

    async function to_string(message) {
        if (typeof message === "string") {
            return message;
        } else if (message instanceof Blob) {
            return await blob_to_string(message);
        } else if (message instanceof ArrayBuffer) {
            return ArrayBuffer_to_string(message);
        } else {
            console.log("unknown yet", message);
            return String(message);
        }
    }

    async function onmessage(event) {
        const str = await to_string(event.data);
        const json_message = JSON.parse(str);
        console.log(json_message);

        const {type, sender, receiver, message} = json_message;
        switch (type) {
            case "pong":
                session = json_message.session;
                break;
            case "text":
                result.onmessage && result.onmessage(json_message);
                break;
            case "read":
                result.onmessage && result.onmessage(json_message);
                break;
            default:
                result.onmessage && result.onmessage(json_message);
                break;
        }
    }

    async function onerror(event) {
        console.log(event);
    }

    async function onclose(event) {
        console.log(event);
    }


    function send_socket_message(msg) {
        // {receiver, type = "message", message}
        // const msg = {
        //     type,
        //     sender: localStorage.getItem("user"),
        //     receiver,
        //     session,
        //     token: localStorage.getItem("hash"),
        //     index,
        //     message
        // };
        index += 1;
        msg.session = session;
        msg.index = index;
        // msg.token = msg.token || meta.token;

        const string_message = JSON.stringify(msg);
        return socket.send(string_message);
    }


    async function setup_websocket() {
        try {
            const protocol = window.location.protocol.includes("https") ? "https" : "http";
            const ws_protocol = protocol.includes("https") ? "wss" : "ws";
            const ws_url = `${ws_protocol}://${window.location.host}`;
            socket = new WebSocket(ws_url);

            socket.onopen = function onopen(event) {
                const {name, token, languages} = meta;
                send_socket_message({type: "ping", message: "hello", sender: name, token, languages});
            };
            socket.onmessage = onmessage;
            socket.onerror = onerror;
            socket.onclose = onclose;
        } catch (error) {
            console.log(error);
        }
    }

    // await setup_websocket();

    return result;
};