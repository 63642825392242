export default function Projectile(options) {
    const me = {
        ...options,
        update,
        draw,
        clear,

    };

    let img;

    function clear(ctx) {
        if (me.img.value) {
            ctx.fillStyle = me.background;
            ctx.fillRect(me.x - me.img.value.width / 2, me.y, me.img.value.width, me.img.value.height);
        }
    }

    function update() {
        me.x = me.x + me.dx;
        me.y = me.y + me.dy;
    }

    function draw(ctx) {
        if (me.img.value) {

            ctx.drawImage(me.img.value, me.x - (me.width || me.img.value.width) / 2, me.y, me.width || me.img.value.width, me.height || me.img.value.height);
        } else {
            ctx.beginPath();
            ctx.fillStyle = me.color;
            ctx.arc(me.x, me.y, me.radius, 0, Math.PI * 2);
            ctx.fill();
        }
    }

    return me;
}