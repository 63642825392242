import "./Slicer.css";
import Game from "./Game.js";
import Orientation from "./Orientation.jsx";
import IconChevronsLeft from "solidjs-feather/IconChevronsLeft.jsx";
import IconChevronsRight from "solidjs-feather/IconChevronsRight.jsx";
import play, {speak} from "./play.js";
import Ignitor from "./Ignitor.jsx";
import {db, fetch_vocabulary, meta} from "./store.jsx";
import {RefImage, RefAudio} from "./RefObject.js";
import Keyboard from "./game/Keyboard.js";
import Pointer from "./game/Pointer.js";
import Player from "./game/Player.js";
import {Bullet} from "./game/Bullet.js";
import Word from "./game/Word.js";
import Projectile from "./game/Projectile.js";
import Star from "./game/Star.js";
import Meteor from "./game/Meteor.js";
import {ref} from "nano/reactive.jsx";
import {route} from "./route.jsx";

console.log("GAME");

export async function Slicer(props) {
    const audio = new Audio("/Prototype.aac");
    audio.load();

    const [game, start] = await SlicerGame({on_finish, audio});

    async function on_finish(event) {
        await (route("/menu")(event));
    }

    // start();
    // requestAnimationFrame(start);
    return (<div id="app" class="page slicer">
        {game}
        <Ignitor start={start}/>
    </div>);
}

const MINUTE = 60 * 1000;

export async function SlicerGame(props, children) {
    // const {words} = props;
    const {on_finish, max_game_time = 10 * MINUTE, audio} = props;
    console.log(props);

    // Orientation("landscape");
    const game = Game();
    let prev_time;
    let frame_time = 16;
    const {canvas, offscreen_canvas, offscreen_ctx, ctx, devicePixelRatio} = game;

    // 34 * 50
    const bullets_images = {
        "n": RefImage("/BlueBullet.webp"),
        "v": RefImage("/GreenBullet.webp"),
        "a": RefImage("/YellowBullet.webp"),
        "g": RefImage("/RedBullet.webp"),
    };
    const bullets_hues = {
        "n": 220,
        "v": 40,
        "a": 160,
        "g": 250,
    };

    // const bullet_img = RefImage("/bullet.webp");
    // const bullet_large_img = RefImage("/bullet_large.webp");

    const background_img = RefImage("/background.webp");
    const spaceship_img = RefImage("/spaceship.webp");


    const background = `rgba(0, 0, 0, 1)`;
    const explosion_radius = 3;
    const explosion_speed = 4;
    const margin = 20;
    const player_radius = 20;
    const projectile_interval = 15;
    const word_interval = 90; // 1.5 / second
    const projectile_radius = 5;
    const projectile_speed = -3;
    let frames = 0;

    let projectiles = [];
    let prev_fps = 60;
    let fps = 0;
    let health = ref(10);
    let times_up = false;

    let bullet = "g";
    let hue = bullets_hues[bullet];

    const keyboard = Keyboard();
    const pointer = Pointer();
    const language = meta.value.learning_languages[0];
    const use_language = meta.value.mother_tongues[0];
    const vocabulary_length = await fetch_vocabulary(language);
    const all_vocabulary = await db.get(language, 1);
    const mastered = new Set();
    const vocabulary = all_vocabulary.filter(word => !mastered.has(word[language]));
    let stars = Array(20).fill(0).map(_ => {
        return Star({
            x: Math.random() * innerWidth,
            y: Math.random() * innerHeight,
            dx: 0,
            dy: 0.3,
            radius: Math.random() * 1 + 1.2,
            color: "white",
            background,
        });
    });
    let meteors = [];

    let words = [
        // Word({text: ["abandon", "a", "ban", "don"], background}),
        // Word({
        //     // text: ["abandon", "a", "ban", "don"],
        //     text: ["above", "[əˈbʌv]", "prep. 在…上面 a. 上面的 ad. 在…之上", "above the door", "fly above clouds", "look above"],
        //     background,
        //     x: innerWidth / 2,
        //     explosion_radius,
        //     explosion_speed,
        //     color: `hsl(${~~(Math.random() * 360)}, 50%, 50%)`,
        // }),
        // Word({text: ["abnormal", "ab", "nor", "mal"], background}),
    ];
    let particles = [];

    const player = Player({
            x: window.innerWidth / 2,
            y: window.innerHeight - player_radius - margin,
            // dx: 1,
            // a: 0.1,
            img: spaceship_img,
            background,
            radius: player_radius,
            color: "#889"
        }
    );

    const bullets = [
        // Bullet({img: bullets_images["n"], x: 30, y: innerHeight - 250}),
        // Bullet({img: bullets_images["v"], x: 30, y: innerHeight - 195}),
        // Bullet({img: bullets_images["a"], x: 30, y: innerHeight - 142}),
        // Bullet({img: bullets_images["g"], x: 30, y: innerHeight - 80})
    ];

    const times = {};

    function loop(objects, fun, options, name) {
        const start = performance.now();

        for (let i = 0, len = objects.length; i < len; i += 1) {
            const object = objects[i];
            object[fun](options);
        }
        times[name] = (times[name] || 0) + performance.now() - start;
    }

    function update(ctx) {
        frames += 1;

        loop(stars, "update", ctx, "update stars");

        loop(meteors, "update", ctx, "update meteors");

        if (frames % 200 === 0) {
            const r = Math.random();
            meteors.push(Meteor({
                x: r > 0.5 ? r * innerWidth + innerWidth : -r * innerWidth,
                y: Math.random() * innerHeight - innerHeight,
                dx: r > 0.5 ? -r * 4 : r * 8,
                dy: Math.random() * 4 + 3,
                color: "white",
                radius: Math.random() * 2
            }));
        }
        // }
        player.update(pointer);

        if (frames % projectile_interval === 0) {
            add_projectile();
        }

        if (frames % word_interval === 0) {
            add_word();
        }

        loop(words, "update", ctx, "update words");
        loop(projectiles, "update", ctx, "update projectiles");
        loop(particles, "update", ctx, "update particles");

        loop(words, "resolve_collision", {ctx, player, projectiles, particles}, "resolve word_collision");

        health.value = player.health;

        if (frames % 1000 === 0) {
            console.log(times);
        }
    }

    function gc() {

        projectiles = projectiles.filter(projectile => projectile.y > 0 && !projectile.collide);
        words = words.filter(word => {
            return word.y < (innerHeight + word.height) && word.finished !== true;
        });
        particles = particles.filter(function visible(particle) {
            return particle.alpha > 0;
        });
        if (frame_time > 17) {
            stars = [];
        }
        meteors = meteors.filter(object => object.x > -innerWidth && object.x < (2 * innerWidth) && object.y < (innerHeight + margin));
    }

    function clear(ctx) {

        loop(stars, "clear", ctx, "clear star");
        loop(projectiles, "clear", ctx, "clear projectiles");
        loop(words, "clear", ctx, "clear words");

        player.clear(ctx);
    }


    function draw(ctx) {
        ctx.fillStyle = `rgba(0, 0, 0, 0.4)`;
        ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
        // ctx.fillText(fps || prev_fps, innerWidth - 100, 20);
        // ctx.drawImage(background_img, 0, 0, innerWidth, innerHeight);

        loop(stars, "draw", ctx, "draw star");
        loop(meteors, "draw", ctx, "draw meteosr");

        loop(bullets, "draw", ctx, "draw bullets");
        loop(words, "draw", ctx, "draw words");
        loop(projectiles, "draw", ctx, "draw projectiles");
        loop(particles, "draw", ctx, "draw particles");

        player.draw(ctx);
    }


    setInterval(() => {
        // console.log(fps);
        prev_fps = fps;
        fps = 0;
    }, 1000);

    function animate() {
        let now = performance.now();
        frame_time = now - prev_time;
        prev_time = now;

        fps += 1;
        clear(offscreen_ctx);
        update(offscreen_ctx);


        gc(offscreen_ctx);
        draw(offscreen_ctx);
        ctx.drawImage(offscreen_canvas, 0, 0, offscreen_canvas.width, offscreen_canvas.height, 0, 0, innerWidth, innerHeight); //canvas.width, canvas.height);
        if (player.health <= 0 || times_up) {
            game_over(ctx);

        } else {
            requestAnimationFrame(animate);
        }
    }


    const word_margin = 50;

    function add_word() {
        const i = ~~(Math.random() * vocabulary.length);
        const word = vocabulary[i];

        words.push(Word({
            // text: ["abandon", "a", "ban", "don"],
            word,
            use_language,
            language,
            background,
            x: ~~(Math.random() * (innerWidth - word_margin) + word_margin),
            explosion_radius,
            explosion_speed,
            color: `hsl(${~~(Math.random() * 360)}, 50%, 50%)`,
        }));
    }

    function add_projectile() {

        const projectile = new Projectile({
            x: ~~player.x,
            y: ~~player.y,
            dx: 0,
            dy: projectile_speed,
            radius: projectile_radius,
            color: `hsla(${hue}, 50%, 50%, 1)`,
            img: bullets_images[bullet],
            width: 22,
            height: 32,
            background,
            velocity: null
        });
        projectiles.push(projectile);
    }

    function onclick(event) {
        // console.log(event);
        // add_projectile(event);
    }

    async function start() {
        window.addEventListener("keydown", keyboard.onkeydown);
        window.addEventListener("keyup", keyboard.onkeyup);
        window.addEventListener("click", onclick);
        window.addEventListener("touchstart", pointer.ontouchstart);
        window.addEventListener("touchmove", pointer.ontouchmove);
        window.addEventListener("touchend", pointer.ontouchend);
        window.addEventListener("touchcancel", pointer.ontouchend);

        window.addEventListener("mousedown", pointer.onmousedown);


        audio.play();
        const canvas = document.getElementById("canvas");
        canvas.style.display = "unset";
        prev_time = performance.now();

        animate();
        if (max_game_time) {
            setTimeout(() => {
                times_up = true;
            }, max_game_time);
        }
    }

    function game_over(ctx) {
        console.log("GAME OVER");
        ctx.font = `50px monospace`;

        // ctx.fillText("GAME OVER", window.innerWidth / 2, window.innerHeight / 2);
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = `hsla(0, 50%, 50%, 1)`;
        audio.pause();
        ctx.fillText(times_up ? "TIMES UP" : "GAME OVER", window.innerWidth / 2, window.innerHeight / 2);

        window.removeEventListener("keydown", keyboard.onkeydown);
        window.removeEventListener("keyup", keyboard.onkeyup);
        window.removeEventListener("click", onclick);
        window.removeEventListener("touchstart", pointer.ontouchstart);
        window.removeEventListener("touchmove", pointer.ontouchmove);
        window.removeEventListener("touchend", pointer.ontouchend);
        window.removeEventListener("touchcancel", pointer.ontouchend);

        window.removeEventListener("mousedown", pointer.onmousedown);

        if (on_finish) {
            setTimeout(() => {
                const audio = document.getElementById("audio");

                const canvas = document.getElementById("canvas");
                canvas.style.display = "none";
                on_finish();
            }, 2000);
        }
    }

    const style_n = {"--h": bullets["n"]};
    const style_v = {"--h": bullets["v"]};
    const style_a = {"--h": bullets["a"]};
    const wealth_style = {"--content": 180, "--translate": "-80%", "--background": "orange"};
    const health_style = {"--content": health, "--translate": "-80%", "--background": "rgb(255, 59, 48)"};

    function set_bullet(pos) {
        return function onclick(event) {
            event.preventDefault();
            event.stopPropagation();
            bullet = pos;
            hue = bullets[pos];
            player.color = `hsla(${hue}, 50%, 50%, 1)`;
        };
    }

    return [(
        <div id="slicer" class="game" onclick={onclick}
        >
            {/*<div class="game_bars">*/}
            {/*    <div class="game_bar game_wealth" style={wealth_style} data-content="180"></div>*/}
            {/*    <div class="game_bar game_health" style={health_style} data-content={health}></div>*/}
            {/*</div>*/}
            {/*<div class="game_bullets">*/}
            {/*    <div class="game_bullet game_bullet_n" style={style_n} ontouchclick={set_bullet("n")}>*/}
            {/*        <img src="/BlueBullet.webp" alt="v"/>*/}
            {/*        <div>v.</div>*/}
            {/*    </div>*/}
            {/*    <div class="game_bullet game_bullet_a" style={style_a} ontouchclick={set_bullet("a")}>*/}
            {/*        <img src="/YellowBullet.webp" alt="a"/>*/}
            {/*        <div>n.</div>*/}
            {/*    </div>*/}
            {/*    <div class="game_bullet game_bullet_v" style={style_v} ontouchclick={set_bullet("v")}>*/}
            {/*        <img src="/GreenBullet.webp" alt="a"/>*/}
            {/*        <div>a.</div>*/}
            {/*    </div>*/}
            {/*    <div class="game_bullet game_bullet_v" style={style_v} ontouchclick={set_bullet("g")}>*/}
            {/*        <img src="/RedBullet.webp" alt="a"/>*/}
            {/*        <div>g</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    ), start];

}

export const SyllableMaster = Slicer;

function handleOrientation(event) {
    const gamma = event.gamma; // Rotation around the y-axis (-90 to 90 degrees)
    console.log(gamma, event);
    //
    // if (gamma > 10) {
    //     // Tilted to the right
    //     player.x += player.speed;
    // } else if (gamma < -10) {
    //     // Tilted to the left
    //     player.x -= player.speed;
    // }
    //
    // // Ensure player stays within canvas bounds
    // if (player.x < 0) {
    //     player.x = 0;
    // } else if (player.x + player.width > canvas.width) {
    //     player.x = canvas.width - player.width;
    // }
}

// Request permission for iOS 13+ devices
function requestPermission(event) {
    if (typeof DeviceOrientationEvent.requestPermission === "function") {
        console.log(`request DeviceOrientationEvent`);
        DeviceOrientationEvent.requestPermission().then(permissionState => {
            if (permissionState === "granted") {
                window.addEventListener("deviceorientation", handleOrientation);
            }
        }).catch(console.error);
    } else {
        // Non iOS 13+ devices
        console.log(`add DeviceOrientationEvent`);
        window.addEventListener("deviceorientation", handleOrientation);
    }
}