import api from "./api.jsx";
import Signup from "./Signup.jsx";
import {sha256} from "nano/lib/security.js";
import {meta} from "./store.jsx";

function gen_token() {
    const t = ~~(performance.now() * 1000);
    const r = ~~(Math.random() * 999999);
    const token = t * 1000000 + r;
    return token.toString(16);
}

console.log("login");
window.sha256 = sha256;

export default async function login(callback) {

    const token = localStorage.getItem("token");
    let need_login = true;
    if (token) {
        const username = localStorage.getItem("username");
        const password_hash = localStorage.getItem("password_hash");

        const response = await api.get("/api/login", {token, username, password_hash});
        if (response.ok) {
            meta.value = response;
            need_login = false;
        }
    }
    if (need_login) {
        const signup = Signup({type: "Login", on_finish: callback}, []);
        console.log(signup);
    } else {
        callback();
    }
}