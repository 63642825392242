import "./MatchMaking.css";

import {route} from "./route.jsx";
import IconMessageSquare from "solidjs-feather/IconMessageSquare.jsx";
import IconGrid from "solidjs-feather/IconGrid.jsx";
import Toolbar from "./Toolbar.jsx";
import {computed, ref, watch} from "nano/reactive.jsx";

import {translation_history, update_history, target_languages, meta, history_empty} from "./store.jsx";
import LanguageBar from "./LanguageBar.jsx";

import Matcher from "./Matcher.jsx";
import {Render} from "nano/nano.jsx";
import {goto_next_page_delay, per_page_words} from "./config.jsx";

console.log("MatchMaking");


export default async function MatchMaking(props, children) {
    // const words = [
    //     [
    //         {language: "english", icon: "🇬🇧", text: "hello"},
    //         {language: "chinese", icon: "🇨🇳", text: "你好"}],
    //     [
    //         {language: "english", icon: "🇬🇧", text: "good morning"},
    //         {language: "chinese", icon: "🇨🇳", text: "早上好"}],
    //     [
    //         {language: "english", icon: "🇬🇧", text: "bye bye"},
    //         {language: "chinese", icon: "🇨🇳", text: "再见"}],
    //     [
    //         {language: "english", icon: "🇬🇧", text: "how are you"},
    //         {language: "chinese", icon: "🇨🇳", text: "你好吗"}],
    //     [
    //         {language: "english", icon: "🇬🇧", text: "lunch"},
    //         {language: "chinese", icon: "🇨🇳", text: "午饭"}],
    //     // 🇬🇧🇨🇳🇫🇷🇯🇵🇸🇪
    // ];
    const current = ref(0);
    let index = ref(0);

    let init = true;
    const selected_languages = computed(() => meta.value.selected_languages);
    const left_language = computed(() => selected_languages.value[0]);
    const right_language = computed(() => selected_languages.value[1]);

    const dom0 = ref(await new_match("page0"));
    const dom1 = ref(); // set empty to avoid flash of the next page

    async function next(update = false) {
        const length = translation_history.value.length || 1;

        if (update || index.value > length - 5) {
            await update_history();
            index.value = 0;
        }

        const words = translation_history.value.slice(index.value, index.value + per_page_words);
        index.value += per_page_words;
        return words;
        // const languages = selected_languages.value;
        //
        // return words.map(word => word.filter(({language}) => languages.includes(language)));
    }

    async function new_match(page) {
        if (history_empty.value) {
            const dom = Render(() => {
                return (
                    <div class="content">
                        <div style={{"font-size": "2rem"}}>EMPTY</div>
                    </div>);
            });
            return dom;
        } else {
            const words = await next();

            const dom = Render(() => {
                return Matcher({
                    words,
                    on_finish,
                    page,
                    left_language: left_language.value,
                    right_language: right_language.value,
                });
            });
            console.log(dom);
            return dom;
        }
    }

    async function on_finish(start) {
        if (current.value === 0) {
            dom1.value = await new_match("page1");
        } else {
            dom0.value = await new_match("page0");
        }

        setTimeout(() => goto_next_page(start), goto_next_page_delay);
    }


    watch(selected_languages, async (new_value, old_value) => {
        console.log(new_value, old_value);
        if (new_value.length === 2) {
            if (JSON.stringify(new_value) === JSON.stringify(old_value)) {
                // still same, nothing need to be done
            } else if (init) {
                // the first time, the dom already initialized
                // avoid to flash by not reinit again
                init = false;
            } else {
                await on_finish(Date.now());
            }
        } else {
            if (current.value === 0) {
                dom0.value = undefined;
                // right_words1.value = [];
            } else {
                dom1.value = undefined;
                // left_words0.value = [];
                // right_words0.value = [];
            }
        }
    });

    function goto_next_page(start) {
        // move to next page
        console.log(`${Date.now() - start}ms move to next page`);
        current.value = current.value === 0 ? 1 : 0;
    }


    return (
        <div id="app" class="page match">
            <Toolbar/>
            <LanguageBar selected_languages={selected_languages}/>
            <div class="content_container left">
                <div _dom={dom0}
                     class={{
                         active: computed(() => current.value === 0)
                     }}>
                </div>
                <div _dom={dom1}
                     class={{
                         active: computed(() => current.value === 1)
                     }}>
                </div>
            </div>
        </div>
    );
}