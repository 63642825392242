import For from "nano/For.jsx";
import IconMessageSquare from "solidjs-feather/IconMessageSquare.jsx";
import IconChevronsLeft from "solidjs-feather/IconChevronsLeft.jsx";
import IconChevronsRight from "solidjs-feather/IconChevronsRight.jsx";
import IconTrash2 from "solidjs-feather/IconTrash2.jsx";
import {computed, ref} from "nano/reactive.jsx";
import {get_lang_flag} from "nano/unicode.js";
import Swipe from "./swipe.js";
import play from "./play.js";
import {translation_history, update_history, target_languages, meta, fetch_vocabulary, cihuile} from "./store.jsx";
// import {route} from "nano/Router.jsx";
import api from "./api.jsx";
import "./WalkThrough.css";
import {route} from "./route.jsx";
import Toolbar from "./Toolbar.jsx";
import UseSwipe from "./UseSwipe.jsx";
import {Render} from "nano/nano.jsx";
import {clone_object} from "nano/lib/_.js";
import {goto_next_page_delay, per_page_words, get_batch, game_words_interval, max_game_time} from "./config.jsx";
import WalkThroughContent from "./WalkThroughContent.jsx";
import Matcher from "./Matcher.jsx";
import {db} from "./store.jsx";
import {SlicerGame} from "./Slicer.jsx";

export default async function Learn(props, children) {

    let {level, language} = props;
    const languages_visible = {
        swedish: true,
        english: true,
        chinese: true
    };
    const current = ref(0);
    let i_match = 1;
    let number_matches = 3;
    let game_just_played = false;
    const removing0 = ref(false);
    const removing1 = ref(false);

    let words_learned = 0;

    language = language || meta.value.learning_languages[0];

    const test = false;
    if (test) {
        await db.delete(language, "level");
        await db.delete(language, "mastered");
        await db.delete(language, `${level}_meta`);
    }
    level = level || await db.get_put_if_not_exist(language, "level", "green");
    const mastered = await db.get_put_if_not_exist(language, "mastered", new Set());
    // await db.get('swedish', 'green_progress')
    // db.put('translingo', 'green_progress', {batch: 1, short_index: 5, middle_index: 0, long_index: 0})
    let basket = [];
    let current_word;
    const level_progress = await db.get_put_if_not_exist(language, `${level}_progress`, {
        batch: get_batch(level),
        short_index: 0,
        middle_index: 0,
        long_index: 0
    });
    level_progress.start_index = level_progress.short_index;
    level_progress.current_index = 0;
    level_progress.middle_index = level_progress.middle_index || 0;
    level_progress.long_index = level_progress.long_index || 0;


    const vocabulary_length = await fetch_vocabulary(language);
    const all_vocabulary = await db.get(language, level_progress.batch) || [];
    const vocabulary = all_vocabulary.filter(word => !mastered.has(word[language]));


    const type = ref("walkthrough");
    const color = computed(() => type.value === "game" ? "white" : undefined);
    const [dom, next_type] = await new_content(1);
    const dom0 = ref(dom);
    const dom1 = ref(); // set empty to avoid flash of the next page
    const selected_languages = computed(() => meta.value.selected_languages);
    const left_language = computed(() => language); //selected_languages.value[0]);
    const right_language = computed(() => language === "english" ? meta.value.mother_tongues[0] : "english"); // selected_languages.value[1]);

    // const translations = computed(() => {
    //     const i = index.value;
    //     const v = translation_history.value[i] || [];
    //     console.log(i, v);
    //     return v.map(({language, icon, text}) => ({language, icon, text}));
    // });


    function goto_next_page({start, next_type}) {
        // move to next page
        // console.log(`${Date.now() - start}ms move to next page`);
        current.value = current.value === 0 ? 1 : 0;
        type.value = next_type;
        console.log(next_type, current.value, dom0.value, dom1.value);
    }

    // triggered by prev/next
    async function update_content(shift, masted_current_word = false) {
        const [dom, next_type] = await new_content(shift, masted_current_word);
        if (current.value === 0) {
            dom1.value = dom;
        } else {
            dom0.value = dom;
        }
        setTimeout(() => goto_next_page({next_type}), 100);
    }

    // triggered by the child finish task
    async function on_finish(start) {
        const [dom, next_type] = await new_content(1);
        if (current.value === 0) {
            dom1.value = dom;
        } else {
            dom0.value = dom;
        }

        setTimeout(() => goto_next_page({start, next_type}), goto_next_page_delay);
    }

    async function new_content(shift, masted_current_word = false) {
        let dom;

        // if not masted_current_word(removed) then prepare for matching game
        if (masted_current_word) {
            mastered.add(current_word[language]);
            await db.put(language, "mastered", mastered);
        } else if (current_word) {
            basket.push(current_word);
        }

        // match making after the first 5 finished walk through
        // if (meta.current_index % per_page_words === 0 && meta.current_index !== 0 && type.value !== "match") {
        if (words_learned > 0 && words_learned % game_words_interval === 0 && !game_just_played) {
            // index.value = (index.value + 1) % length;
            // the type need to be changed after the dom mounted, otherwise
            // it will not trigger values which depend on type.value
            const audio = new Audio("/Prototype.aac");
            audio.load();
            const [game, start] = await SlicerGame({on_finish, max_game_time, audio});
            // requestAnimationFrame(start);
            start();
            type.value = "game";
            game_just_played = true;
            await db.put(language, `${level}_progress`, level_progress);
            // basket = [];
            return [game, "game"];
        } else if (basket.length === per_page_words) {
            // const words = vocabulary.slice(meta.index - per_page_words, meta.index);

            dom = Render(() => {
                return Matcher({
                    words: clone_object(basket.map((word, i) => ({...word, i}))),
                    on_finish,
                    page: `page${current.value}`,
                    left_language: left_language.value,
                    right_language: right_language.value,
                });
            });
            console.log(dom);
            if (i_match === number_matches) {
                words_learned += basket.length;
                game_just_played = false;
                basket = [];

            } else {
                i_match += 1;
            }
            current_word = undefined;

            // index.value = (index.value + 1) % length;
            // the type need to be changed after the dom mounted, otherwise
            // it will not trigger values which depend on type.value
            type.value = "match";
            return [dom, "match"];
            // requestAnimationFrame(() => type.value = "match");
        }
        // walk through
        else {
            console.log(level_progress);
            i_match = 1;
            current_word = vocabulary[level_progress.short_index] || [];
            level_progress.short_index += 1;
            level_progress.current_index += 1;

            dom = Render(() => {
                return WalkThroughContent({
                    word: current_word,
                    languages_visible
                });
            });

            // the type need to be changed after the dom mounted, otherwise
            // it will not trigger values which depend on type.value
            // requestAnimationFrame(() => type.value = "walkthrough");
            type.value = "walkthrough";
            return [dom, "walkthrough"];
        }

    }

    const left = ref(false);

    // swipe right, goes to the prev
    async function prev(event) {
        left.value = false;
        await update_content(-1);
    }

    async function next(event, masted_current_word) {
        left.value = true;
        await update_content(1, masted_current_word);
    }

    function remove(event) {

        if (current.value === 0) {
            removing0.value = true;
        } else {
            removing1.value = true;
        }
        setTimeout(async () => {
            if (current.value === 0) {
                dom0.value = undefined;
            } else {
                dom1.value = undefined;
            }

            await next(event, true);
            removing0.value = false;
            removing1.value = false;
        }, 300);
    }


    async function onclicktest(event) {
        console.log(event);
        await play("test", "en-US");
    }

    return (
        <div class={{
            page: true,
            walkthrough: computed(() => type.value === "walkthrough"),
            match: computed(() => type.value === "match")
        }
        } id="app">
            <Toolbar hide={computed(() => type.value === "game" || cihuile)}/>
            {/*<audio controls>*/}
            {/*    <source src="/bubbles-single3.wav" type="audio/wav"/>*/}
            {/*</audio>*/}
            {/*<button class="button left overlay" ontouchclick={prev}><IconChevronsLeft/></button>*/}
            <div class={{content_container: true, left}}>
                <div _dom={dom0}
                     class={{
                         active: computed(() => current.value === 0),
                         removing: removing0
                     }}>
                </div>
                <div _dom={dom1}
                     class={{
                         active: computed(() => current.value === 1),
                         removing: removing1
                     }}>
                </div>
            </div>
            <button class="button right overlay" onclick={next} _hide={computed(() => type.value !== "walkthrough")}>
                <IconChevronsRight/></button>
            {/*<div class="message_bar">*/}
            {/*    <span onclick={route("/")}><IconMessageSquare/></span>*/}
            {/*    <span onclick={route("/menu")}><IconGrid/></span>*/}
            {/*</div>*/}
            <button class="button bottom overlay" onclick={remove} _hide={computed(() => type.value !== "walkthrough")}><IconTrash2/>
            </button>
        </div>
    );
}