export default function Game({width = window.innerWidth, height = window.innerHeight} = {}) {
    const canvas = document.getElementById("canvas");
    const offscreen_canvas = document.createElement("canvas");

    const devicePixelRatio = window.devicePixelRatio;

    // const aspectRatio = 1024 / 576;
    let prev_size = {width, height};
    // if (window.innerWidth / window.innerHeight > aspectRatio) {
    //     // Window is wider than the aspect ratio
    //     canvas.style.width = `${window.innerHeight * aspectRatio}px`;
    //     canvas.style.height = `${window.innerHeight}px`;
    // } else {
    //     // Window is taller than the aspect ratio
    //     canvas.style.width = `${window.innerWidth}px`;
    //     canvas.style.height = `${window.innerWidth / aspectRatio}px`;
    // }

    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    offscreen_canvas.width = canvas.width;
    offscreen_canvas.height = canvas.height;

    const ctx = canvas.getContext("2d");
    ctx.scale(devicePixelRatio, devicePixelRatio);

    const offscreen_ctx = offscreen_canvas.getContext("2d");
    offscreen_ctx.scale(devicePixelRatio, devicePixelRatio);


    const me = {
        width,
        height,
        ctx,
        offscreen_ctx,
        x: 0,
        y: 0,
        left: {pressed: false},
        right: {pressed: false},
        up: {pressed: false},
        down: {pressed: false},
        canvas,
        offscreen_canvas
    };


// function set_height() {
//     document.body.style.height = `${window.innerHeight}px`
// }
// set_height()
    let resize_timeout;

    function onresize() {
        resize_timeout && clearTimeout(resize_timeout);
        resize_timeout = setTimeout(() => {
            resize();
        }, 500);
    }

    function resize() {
        // set_height()
        canvas.width = window.innerWidth * devicePixelRatio;
        canvas.height = window.innerHeight * devicePixelRatio;
        ctx.scale(devicePixelRatio, devicePixelRatio);
        const x_ratio = window.innerWidth / prev_size.width;
        const y_ratio = window.innerHeight / prev_size.height;
        console.log({x_ratio, y_ratio});
        // for (let i = 0, len = particles.length; i < len; i += 1) {
        //     const particle = particles[i];
        //     particle.x = particle.x * x_ratio;
        //     particle.y = particle.y * y_ratio;
        // }

        prev_size = {width: window.innerWidth, height: window.innerHeight};
        // active_game.resize() // gen_particles()
    }


    // window.addEventListener("keydown", onkeydown);
    // window.addEventListener("keyup", onkeyup);
    // window.addEventListener("mousemove", onmousemove);
    // window.addEventListener("touchmove", ontouchmove);
    // window.addEventListener("resize", onresize);

    return me;
}